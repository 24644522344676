import React, { useState } from 'react';
import './Services.css';

function Services() {
  // State for each modal
  const [activeModal, setActiveModal] = useState(null);

  // Modal content for each service
  const modalContent = {
    drycleaning: {
      title: "Dry Cleaning Services",
      intro: "Our state-of-the-art dry cleaning process ensures your garments receive the best possible care.",
      details: [
        "Men's Laundered Dress Shirts: $3.35",
        "Standard Dry Cleaning Items: $8.80",
        "Comforters: $25-$35 (based on size)",
        "Same-Day Service Available at some stores (in by 9am, out by 5pm)",
        "Eco-Friendly Cleaning Solutions",
        "Expert Stain Removal",
        "Specialized Care for Delicate Fabrics"
      ],
      note: "All garments are thoroughly inspected and carefully processed to maintain their quality and extend their life. Prices may vary for specialty items or fabrics requiring extra care."
    },
    alterations: {
      title: "Alterations Service",
      intro: "Our expert tailors provide professional alterations to ensure your garments fit perfectly.",
      details: [
        "Basic Alterations: $15",
        "Zipper Replacement: $25",
        "Standard Service: 5 business days"
      ],
      note: "Need it faster? Visit our Park Avenue location for possible expedited alteration service."
    },
    delivery: {
      title: "Free Delivery Service",
      intro: "Convenient, contactless pickup and delivery right to your door.",
      details: [
        "No Minimum Order Required",
        "Text Updates about your delivery day",
        "Secure Online Account Access"
      ],
      note: "Sign up online or call 515-986-1106 to start your free delivery service today!"
    },
    laundry: {
      title: "Wash & Fold Services",
      intro: "Professional laundry service with attention to detail.",
      details: [
        "Priced at $2.50 per pound",
        "48-Hour turnaround at most locations",
        "Folded and packaged neatly"
      ],
      note: "Perfect for busy professionals and families looking to save time on laundry."
    },
    household: {
      title: "Household Items",
      intro: "Expert care for your home's fabrics and textiles.",
      details: [
        "Comforters & Duvets",
        "Blankets & Throws",
        "Table Linens",
        "Prices range from $18-$35, depending on size and thickness"
      ],
      note: "Special packaging available for seasonal storage of household items."
    },
    wedding: {
      title: "Wedding Gown Services",
      intro: "Specialized care for your precious wedding attire.",
      details: [
        "Professional Cleaning and Preservation Services",
        "Prices range from $200 for cleaning and pressing, to $275 for cleaning and preservation",
        "Custom Storage Box",
      ],
      note: "Most wedding gowns are completed within 2-4 weeks."
    },
    curtains: {
      title: "Curtains & Tablecloths",
      intro: "Professional cleaning for your home's window treatments and fine linens.",
      details: [
        "Expert cleaning of curtains and draperies",
        "Tablecloth cleaning and pressing",
        "Most items $12-$16 per panel/piece",
        "1-2 week turnaround time",
        "Special handling for delicate fabrics"
      ],
      note: "Please note: We cannot clean pleated curtains or curtains with rubber backing. Most curtains may shrink 1-3 inches in length during cleaning. Pre-existing sun damage may become visible after cleaning."
    },
    uniforms: {
      title: "Uniform Services",
      intro: "Keep your team looking professional with our uniform cleaning services.",
      details: [
        "Professional cleaning for all types of uniforms",
        "Standard dry cleaning rates apply",
        "Weekly service available",
        "Special attention to patches and emblems",
        "Starch levels customized to preference"
      ],
      note: "Perfect for healthcare professionals, service industry workers, and business teams. Ask about our volume pricing for business accounts."
    }
  };

  const toggleModal = (modalId) => {
    setActiveModal(activeModal === modalId ? null : modalId);
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="services">
          <header className="page-header">
            <h1>Services & Pricing</h1>
          </header>

          <section className="services-section">
            <div className="services-grid">
              <div className="service-card">
                <i className="fas fa-tshirt"></i>
                <h3>Dry Cleaning</h3>
                <p>Professional dry cleaning for all your garments.</p>
                <button onClick={() => toggleModal('drycleaning')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-scissors"></i>
                <h3>Alterations</h3>
                <p>Professional alterations and repairs for all types of garments.</p>
                <button onClick={() => toggleModal('alterations')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-truck"></i>
                <h3>Free Delivery</h3>
                <p>Convenient pickup and delivery to your home or office.</p>
                <button onClick={() => toggleModal('delivery')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-box"></i>
                <h3>Wash & Fold</h3>
                <p>Professional wash and fold service for your everyday laundry.</p>
                <button onClick={() => toggleModal('laundry')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-home"></i>
                <h3>Household Items</h3>
                <p>Cleaning for comforters, blankets, and other household items.</p>
                <button onClick={() => toggleModal('household')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-ring"></i>
                <h3>Wedding Gowns</h3>
                <p>Specialized cleaning and preservation for wedding gowns.</p>
                <button onClick={() => toggleModal('wedding')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-table"></i>
                <h3>Curtains & Tablecloths</h3>
                <p>Professional cleaning for curtains, draperies, and fine linens.</p>
                <button onClick={() => toggleModal('curtains')} className="learn-more-btn">Learn More</button>
              </div>

              <div className="service-card">
                <i className="fas fa-tshirt"></i>
                <h3>Uniforms</h3>
                <p>Professional uniform cleaning for businesses and individuals.</p>
                <button onClick={() => toggleModal('uniforms')} className="learn-more-btn">Learn More</button>
              </div>

              {/* Modal */}
              {activeModal && (
                <div className="modal-overlay" onClick={() => toggleModal(null)}>
                  <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                      <h2>{modalContent[activeModal].title}</h2>
                      <button className="close-button" onClick={() => toggleModal(null)}>×</button>
                    </div>
                    <div className="modal-body">
                      <p className="modal-intro">
                        {modalContent[activeModal].intro}
                      </p>
                      
                      <div className="pricing-info">
                        <h3>Service Details</h3>
                        <ul>
                          {modalContent[activeModal].details.map((detail, index) => (
                            <li key={index}>{detail}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="special-note">
                        <p>{modalContent[activeModal].note}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Services;
import React from 'react';
import './About.css';

function About() {
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="services">
                    <section className="services-section">
                        <div className="letter-card">
                            <h1>About Crown Cleaners</h1>
                            
                            <h3>Our Story</h3>
                            <p>Since 1992, Crown Cleaners has been a proud, family-owned business serving the Des Moines, Iowa community. Our mission is simple: to provide top-quality dry cleaning services while prioritizing the well-being of our customers, employees, and the environment. With over three decades of experience, we've built a reputation for delivering exceptional care to every garment entrusted to us, treating each item as if it were our own.</p>

                            <h3>Environmental Commitment</h3>
                            <p>At Crown Cleaners, we believe in staying ahead of the curve by combining traditional values with modern solutions. That's why we've switched from using older, harsh chemicals like Perc to Sensene, a state-of-the-art, alcohol-based solvent that's both safer for you and gentler on the environment(And about TWICE as good at cleaning clothing as a little bonus). This commitment to eco-friendly practices doesn't just reduce our environmental footprint—it ensures that your clothes are cleaned with care and precision, without compromising on quality.</p>

                            <h3>Our Team</h3>
                            <p>We take pride in our team, whose dedication and expertise ensure that every visit to Crown Cleaners is marked by friendly service and meticulous attention to detail. Our family-first approach extends beyond the counter, as we strive to create a workplace that values and supports our employees, fostering a team that's as passionate about our community as we are.</p>

                            <h3>Our Promise</h3>
                            <p>Whether you're here for our convenient 2-day turnaround or exploring our free home delivery service, you'll find that Crown Cleaners is more than a dry cleaner—it's a trusted partner in keeping your wardrobe fresh, clean, and ready for whatever life brings. Thank you for choosing us to care for your garments, and for letting us be part of your day.</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default About;

import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Home.css';  // We'll create this file for the styles
import { cachedReviews, lastUpdated } from '../data/cachedReviews';
import headerMobile from '../assets/images/headerMobile.jpg';    // ~750px wide
import headerTablet from '../assets/images/headerTablet.jpg';    // ~1200px wide
import headerDesktop from '../assets/images/headerDesktop.jpg';  // ~1920px wide

// Only lazy load the full component for desktop
const GoogleReviews = lazy(() => import('../components/ReviewSlider/GoogleReviews'));

// Simple mobile version
const MobileReviews = ({ reviews }) => (
  <div className="mobile-reviews">
    <h2>What Our Customers Say</h2>
    {reviews.slice(0, 3).map((review, index) => (
      <div key={index} className="mobile-review">
        <div className="stars">{'★'.repeat(review.rating)}</div>
        <p>{review.text}</p>
        <small>{review.author}</small>
      </div>
    ))}
    <small className="update-date">Reviews updated: {lastUpdated}</small>
  </div>
);

function Home() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="home">
          {/* Hero Section */}
          <header className="hero" role="banner">
            <picture>
              {/* Desktop */}
              <source
                media="(min-width: 1024px)"
                srcSet={headerDesktop}
                width="1920"
                height="1080"
                aria-hidden="false"
                alt="man in a dry cleaned suit"
              />
              {/* Tablet */}
              <source
                media="(min-width: 768px)"
                srcSet={headerTablet}
                width="1200"
                height="675"
                aria-hidden="false"
                alt="man in a dry cleaned suit"
              />
              {/* Mobile */}
              <img
                src={headerMobile}
                alt="man in a dry cleaned suit"
                className="hero-image"
                width="750"
                height="422"
                loading="eager"
                fetchPriority="high"
              />
            </picture>
            <div className="hero-content" role="heading" aria-level="1">
              <h1>CLEAN CLOTHES, HAPPY PEOPLE</h1>
              <p className="hero-slogan" aria-label="Our slogan">Des Moines' Family Dry Cleaners & Laundry</p>
            </div>
          </header>

          {/* Text Boxes Section */}
          <section className="text-boxes">
            <div className="container">
              <div className="text-box">
                <h2>Quality Without Compromise</h2>
                <p>
                  As a family-owned business, we've invested in the best equipment and 
                  eco-friendly processes to deliver exceptional results at competitive prices. 
                  From everyday wear to special garments, experience the difference that 
                  three decades of expertise brings to your wardrobe.
                </p>
                <Link to="/services" className="text-box-button">Our Services</Link>
              </div>
              
              <div className="text-box">
                <h2>Service That Feels Like Family</h2>
                <p>
                  Our team doesn't just clean clothes – we build relationships. Whether you visit 
                  our stores or use our free delivery service, you'll experience the friendly, 
                  personal care that's kept Des Moines families coming back since 1993.
                </p>
                <Link 
                  to="/delivery" 
                  className="text-box-button"
                  aria-label="Learn more about Crown Cleaners' free delivery service"
                >
                  Learn More About Free Delivery
                </Link>
              </div>
            </div>
          </section>

          {/* Reviews Section - Only visible on desktop */}
          {isDesktop && (
            <Suspense fallback={<div className="reviews-loading">Loading reviews...</div>}>
              <GoogleReviews />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
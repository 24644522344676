import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Locations.css';

// Import location images
import eptrueImg from '../assets/images/epoutside.jpg';
import greenwayImg from '../assets/images/greenwayoutside.jpg';
import fortysecondImg from '../assets/images/42ndOutside.jpg';
import parkaveImg from '../assets/images/parkoutside.jpg';
import grimesImg from '../assets/images/grimesoutside.jpg';
import ankenyImg from '../assets/images/ankenyoutside.jpg';
import sw9thImg from '../assets/images/noahsoutside.jpg';
import booneImg from '../assets/images/cardinaloutside.jpg';

function Locations() {
  const [viewMode, setViewMode] = useState('stores');
  
  useEffect(() => {
    console.log('Locations component mounted');
  }, []);

  console.log('Current view mode:', viewMode);

  const locations = [
    {
      id: 'eptrue',
      name: 'EP True',
      image: eptrueImg,
      address: {
        street: '5018 EP True Parkway',
        cityState: 'West Des Moines',
      },
      phone: '(515) 223-8880',
      hours: {
        weekday: 'Mon-Fri: 6:30am-7pm',
        saturday: 'Sat: 9am-1pm'
      },
      path: '/stores/eptrue'
    },
    {
      id: 'greenway',
      name: 'Greenway',
      image: greenwayImg,
      address: {
        street: '8850 University Ave',
        cityState: 'West Des Moines',
      },
      phone: '(515) 979-6340',
      hours: {
        weekday: 'Mon-Fri: 8am-7pm',
        saturday: 'Sat: 9am-1pm'
      },
      path: '/stores/greenway'
    },
    {
      id: '42nd',
      name: '42nd Street',
      image: fortysecondImg,
      address: {
        street: '863 42nd St',
        cityState: 'Des Moines',
      },
      phone: '(515) 279-0752',
      hours: {
        weekday: 'Mon-Fri: 8am-5pm',
        saturday: 'Sat: 9am-1pm'
      },
      path: '/stores/42nd'
    },
    {
      id: 'parkave',
      name: 'Park Ave',
      image: parkaveImg,
      address: {
        street: '4136 Park Ave',
        cityState: 'Des Moines',
      },
      phone: '(515) 287-4797',
      hours: {
        weekday: 'Mon-Fri: 9am-5pm',
        saturday: 'Sat: 9am-12pm'
      },
      path: '/stores/parkave'
    },
    {
      id: 'grimes',
      name: 'Grimes',
      image: grimesImg,
      address: {
        street: '1451 SE Gateway Cir',
        cityState: 'Grimes',
      },
      phone: '(515) 986-1106',
      hours: {
        weekday: 'Mon-Fri: 10am-6pm',
        saturday: 'Sat: 9am-1pm'
      },
      path: '/stores/grimes'
    },
    {
      id: 'ankeny',
      name: 'Ankeny',
      image: ankenyImg,
      address: {
        street: '917 E 1st St',
        cityState: 'Ankeny',
      },
      phone: '(515) 965-0596',
      hours: {
        weekday: 'Mon-Fri: 6:30am-7pm',
        saturday: 'Sat: 9am-3pm'
      },
      path: '/stores/ankeny'
    },
    {
      id: 'sw9th',
      name: 'Southwest 9th',
      image: sw9thImg,
      address: {
        street: '6115 SW 9th St',
        cityState: 'Des Moines',
      },
      phone: '(515) 285-3131',
      hours: {
        weekday: 'Mon-Fri: 8am-5pm',
        saturday: 'Sat: 10am-12pm'
      },
      path: '/stores/sw9th'
    },
    {
      id: 'boone',
      name: 'Boone',
      image: booneImg,
      address: {
        street: '909 8th St',
        cityState: 'Boone',
      },
      phone: '(515) 432-4853',
      hours: {
        weekday: 'Mon-Fri: 8am-1pm',
        saturday: 'Sat: 10am-12pm'
      },
      path: '/stores/boone'
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="locations">
          <header className="page-header">
            <h1>Our Locations</h1>
          </header>

          <section className="locations-section">
            <div className="container">
              {/* View Toggle */}
              <div className="view-toggle">
                <button 
                  className={`toggle-button ${viewMode === 'stores' ? 'active' : ''}`}
                  onClick={() => setViewMode('stores')}
                >
                  Stores
                </button>
                <button 
                  className={`toggle-button ${viewMode === 'map' ? 'active' : ''}`}
                  onClick={() => setViewMode('map')}
                >
                  Map
                </button>
              </div>

              {/* Stores View */}
              <div className={`locations-grid ${viewMode === 'stores' ? 'view-active' : ''}`}>
                {locations.map(location => (
                  <div key={location.id} 
                    className={`location-card has-bg location-${location.id}`}
                    style={{
                      backgroundImage: `url(${location.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    <h3>{location.name}</h3>
                    <div className="location-details">
                      <p>
                        <i className="fas fa-map-marker-alt"></i> 
                        <span className="address-container">
                          <span className="street-address">{location.address.street}</span>
                          <span className="city-state">{location.address.cityState}</span>
                        </span>
                      </p>
                      <p><i className="fas fa-phone"></i> <span>{location.phone}</span></p>
                      <p><i className="fas fa-clock"></i> <span>{location.hours.weekday}</span></p>
                      <p><i className="fas fa-clock"></i> <span>{location.hours.saturday}</span></p>
                    </div>
                    <div className="location-actions">
                      <Link to={location.path} className="location-button">Store Page</Link>
                    </div>
                  </div>
                ))}
              </div>

              {/* Map View */}
              <div className={`map-view ${viewMode === 'map' ? 'view-active' : ''}`}>
                <iframe 
                  src="https://www.google.com/maps/d/embed?mid=1Qte9xESgeFBwNWpkSxPamwHOlgGMxPQ&z=11&ll=41.67375,-93.73824&layer=stores&noprof=1&msa=0"
                  width="100%"
                  height="750"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Locations;
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>About Crown</h3>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/sensene">Our Cleaning Process</Link></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Contact Us</h3>
          <ul>
            <li><Link to="/locations">Call Us</Link></li>
            <li><Link to="/contact">Contact Form</Link></li>
            <li><a href="mailto:info@crown-cleaners.com">info@crown-cleaners.com</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Hours</h3>
          <ul>
            <li><Link to="/locations">Hours vary by location</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 
import React, { useEffect, useRef, useState } from 'react';
import './FreeDelivery.css';
import kmlData from '../data/days-2.kml';  // Import the KML file
import greenPattern from '../assets/images/greenpattern2.png';  // Add this import
import { useNavigate } from 'react-router-dom';

function FreeDelivery() {
    const signupInputRef = useRef(null);
    const [status, setStatus] = useState('Loading...');
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [deliveryZones, setDeliveryZones] = useState(null);
    const scriptLoadedRef = useRef(false);
    const [email, setEmail] = useState('');
    const [signupStatus, setSignupStatus] = useState('');
    const [showEmailField, setShowEmailField] = useState(false);
    const [hasCheckedAddress, setHasCheckedAddress] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    // Load KML data
    useEffect(() => {
        try {
            setStatus('Loading delivery zones...');
            const parser = new DOMParser();
            fetch(kmlData)
                .then(response => response.text())
                .then(kmlText => {
                    const kmlDoc = parser.parseFromString(kmlText, 'text/xml');
                    const placemarks = kmlDoc.getElementsByTagName('Placemark');
                    
                    if (placemarks.length === 0) {
                        throw new Error('No delivery zones found');
                    }
                    
                    setDeliveryZones(kmlDoc);
                    setStatus('Enter your address');
                })
                .catch(error => {
                    console.error('Error loading KML:', error);
                    setStatus(`Error loading delivery zones: ${error.message}`);
                });
        } catch (error) {
            console.error('Error parsing KML:', error);
            setStatus(`Error loading delivery zones: ${error.message}`);
        }
    }, []);

    const checkDeliveryDay = (lat, lng) => {
        if (!deliveryZones) return 'Unable to check delivery zones';

        // Log the input coordinates
        console.log('Checking coordinates:', { lat, lng });

        const placemarks = deliveryZones.getElementsByTagName('Placemark');
        
        for (const placemark of placemarks) {
            const name = placemark.getElementsByTagName('name')[0]?.textContent;
            const coordinates = placemark.getElementsByTagName('coordinates')[0]?.textContent;
            
            if (!coordinates) continue;

            // Clean and parse the coordinates
            const points = coordinates
                .trim()
                .split('\n')  // Split by newlines
                .map(line => line.trim())  // Clean each line
                .filter(line => line.length > 0)  // Remove empty lines
                .map(coord => {
                    const [lng, lat] = coord.split(',').map(Number);
                    // Only return valid coordinates
                    if (!isNaN(lat) && !isNaN(lng)) {
                        return { lat, lng };
                    }
                    return null;
                })
                .filter(point => point !== null);  // Remove invalid points

            console.log(`Checking zone: ${name}`);
            console.log('Valid zone points:', points);

            if (isPointInPolygon({ lat, lng }, points)) {
                if (name === 'No Delivery!!!!!!!!!') {
                    return 'This address is not eligible for delivery service';
                }
                return `Whooohoo! We are in your neighborhood every ${name}!`;
            }
        }

        return 'Address is outside our delivery area';
    };

    const isPointInPolygon = (point, polygon) => {
        let inside = false;
        
        // Log the point we're checking
        console.log('Checking point:', point);
        
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].lng, yi = polygon[i].lat;
            const xj = polygon[j].lng, yj = polygon[j].lat;
            
            const intersect = ((yi > point.lat) !== (yj > point.lat))
                && (point.lng < (xj - xi) * (point.lat - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        
        // Log the result
        console.log('Is point inside polygon:', inside);
        
        return inside;
    };

    const handleAddressSubmit = async (e) => {
        e.preventDefault();
        if (!selectedAddress) {
            setStatus('Please select an address from the dropdown');
            return;
        }

        setStatus('Checking delivery zone...');
        const day = checkDeliveryDay(selectedAddress.lat, selectedAddress.lng);
        if (day.includes('Your delivery day is')) {
            const dayOnly = day.replace('Your delivery day is ', '');
            setDeliveryInfo(`Whooohoo! We are in your neighborhood every ${dayOnly}! If you want to sign up, enter your email and we will get you all set up!`);
        } else {
            setDeliveryInfo(day);
        }
        setStatus('Ready');
        setShowEmailField(true);
        setHasCheckedAddress(true);
    };

    // Handle address input changes
    const handleAddressChange = () => {
        setHasCheckedAddress(false);
        setDeliveryInfo(null);
        setShowEmailField(false);
        setSelectedAddress(null);
    };

    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        
        try {
            // Log initial values
            console.log('Initial form values:', {
                hasName: !!name,
                hasEmail: !!email,
                hasPhone: !!phone,
                hasAddress: !!selectedAddress,
                hasDeliveryInfo: !!deliveryInfo
            });

            // First check all required fields
            if (!name || !email || !phone || !selectedAddress || !deliveryInfo) {
                throw new Error('Please fill in all required fields');
            }

            // Extract the day with updated regex
            const dayMatch = deliveryInfo.match(/every\s+([^<]+)/i);
            const deliveryDay = dayMatch ? dayMatch[1] : null;

            console.log('Extracted delivery day:', deliveryDay);

            if (!deliveryDay) {
                throw new Error('Could not determine delivery day');
            }

            // Create and log the request body
            const requestBody = {
                name,
                email,
                phone,
                address: selectedAddress.address,
                deliveryDay
            };
            
            console.log('Sending request with body:', requestBody);

            const response = await fetch('https://us-central1-ccwebsite20.cloudfunctions.net/processRouteSignup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const data = await response.json();
            console.log('Server response:', data);

            if (!response.ok) {
                throw new Error(data.error || 'Signup failed');
            }

            setSignupStatus('Thank you for signing up! Check your email for confirmation.');
            setName('');
            setEmail('');
            setPhone('');
            setSelectedAddress(null);
            
        } catch (err) {
            console.error('Error:', err.message);
            setSignupStatus('Sorry, there was an error. Please try again or contact us directly.');
        }
    };

    // Initialize Google Places Autocomplete
    useEffect(() => {
        let autocomplete = null;

        const initAutocomplete = () => {
            try {
                setStatus('Initializing autocomplete...');
                
                // Initialize autocomplete for the signup form
                autocomplete = new window.google.maps.places.Autocomplete(signupInputRef.current, {
                    types: ['address'],
                    componentRestrictions: { country: 'us' },
                    fields: ['formatted_address', 'geometry']
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setSelectedAddress({
                            address: place.formatted_address,
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        });
                        setStatus('Address selected - Press Check Address to continue');
                    }
                });

                setStatus('Enter your address');
            } catch (error) {
                setStatus(`Error: ${error.message}`);
                console.error('Autocomplete initialization error:', error);
            }
        };

        if (!scriptLoadedRef.current && !window.google?.maps?.places) {
            scriptLoadedRef.current = true;
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCwGgap_Ut-JrGG39Uz0XAlNTiVRfdTozI&libraries=places';
            script.async = true;
            script.defer = true;
            script.onload = initAutocomplete;
            document.head.appendChild(script);
        } else if (window.google?.maps?.places) {
            initAutocomplete();
        }

        return () => {
            if (autocomplete) {
                window.google?.maps?.event?.clearInstanceListeners(autocomplete);
            }
        };
    }, []);

    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="delivery-page">
                    <header className="page-header">
                        <h1>Professional Dry Cleaning, Delivered</h1>
                    </header>

                    <section className="delivery-benefits">
                        <div className="benefits-grid">
                            <div className="benefit-card">
                                <i className="fas fa-clock"></i>
                                <h3>Simple Weekly Service</h3>
                                <p>Place your bag on your front porch by 8:00 AM on your designated day. We'll pick up and deliver right to your door.</p>
                            </div>
                            <div className="benefit-card">
                                <i className="fas fa-calendar-check"></i>
                                <h3>Flexible Schedule</h3>
                                <p>No minimum usage required. Service automatically pauses after 4 weeks without pickup - just text us to resume.</p>
                            </div>
                            <div className="benefit-card">
                                <i className="fas fa-dollar-sign"></i>
                                <h3>Free Delivery</h3>
                                <p>Delivery is complimentary for customers spending $25+ monthly on dry cleaning.</p>
                            </div>
                        </div>
                    </section>

                    <section className="initial-setup">
                        <div className="page-header">
                            <h1>Getting Started</h1>
                        </div>
                        <div className="steps-container">
                            <div className="step">
                                <div className="step-number">1</div>
                                <h3>Prepare Your First Bag</h3>
                                <p>Place your items in any bag with your name, address, and phone number inside. We'll provide two complimentary garment bags with your first delivery for future pickups.</p>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <h3>Text Us a Photo</h3>
                                <p>Place your bag on your front porch and text a photo of its location to your dedicated route driver. This helps ensure a smooth first pickup at your home.</p>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <h3>Set Up Payment</h3>
                                <p>You'll receive a welcome text with a link to set up secure payment. You're only charged when you use the service.</p>
                            </div>
                        </div>
                    </section>

                    <section className="signup-section">
                        <h2>Check Your Delivery Day</h2>
                        <p className="privacy-message">
                            We take privacy seriously around here. We won't keep this information, and we don't have salesmen that will call you. We just want to deliver clean clothes to your doorstep.
                        </p>
                        <form onSubmit={handleSignupSubmit} className="signup-form">
                            <div className="input-group">
                                <input 
                                    ref={signupInputRef}
                                    type="text" 
                                    placeholder="Enter your address..."
                                    className="address-input"
                                    onChange={handleAddressChange}
                                />
                                {!hasCheckedAddress && (
                                    <button 
                                        onClick={handleAddressSubmit}
                                        className="crown-address-check-btn"
                                        type="button"
                                    >
                                        CHECK ADDRESS
                                    </button>
                                )}
                            </div>

                            {deliveryInfo && (
                                <div className="delivery-info">
                                    <p>{deliveryInfo}</p>
                                    <div className="team-container">
                                        <div className="team-member">
                                            <i className="fas fa-truck"></i>
                                            <h3>Clarice</h3>
                                            <p>Your Dedicated Delivery Driver</p>
                                            <a href="tel:515-415-0629">(515) 415-0629</a>
                                            <p>Text for pickup location & service updates</p>
                                        </div>
                                        <div className="team-member">
                                            <i className="fas fa-user-circle"></i>
                                            <h3>Miranda</h3>
                                            <p>Route Coordinator</p>
                                            <a href="mailto:miranda@crown-cleaners.com">miranda@crown-cleaners.com</a>
                                            <a href="tel:515-986-1106">(515) 986-1106</a>
                                            <p>Contact for signup questions & assistance</p>
                                        </div>
                                    </div>
                                    
                                    {!deliveryInfo.includes('not eligible') && !deliveryInfo.includes('outside our delivery area') && (
                                        <>
                                            <p className="privacy-message">
                                                We need just a little more information to get you all set up. And oh yeah, did we mention that we love privacy and dislike pushy salesmen? This information isn't going anywhere. We are here to serve you, not annoy you.
                                            </p>
                                            <div className="signup-form-group">
                                                <input 
                                                    type="text" 
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    placeholder="Your Name"
                                                    className="signup-input"
                                                    required
                                                />
                                                <input 
                                                    type="tel" 
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    placeholder="Phone Number"
                                                    className="signup-input"
                                                    required
                                                />
                                                <input 
                                                    type="email" 
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Email Address"
                                                    className="signup-input"
                                                    required
                                                />
                                                <button 
                                                    type="submit" 
                                                    className="signup-button"
                                                    disabled={!email || !name || !phone}
                                                >
                                                    Sign Up Now
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            
                            {signupStatus && (
                                <div className="signup-status">
                                    <p>{signupStatus}</p>
                                </div>
                            )}
                        </form>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default FreeDelivery;

import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
  return (
    <div className="page-wrapper">
      <div className="not-found-container">
        <div className="not-found-content-box">
          <h1>Oops! Page Not Found</h1>
          <p>We couldn't find the page you're looking for.</p>
          <div className="not-found-links">
            <p>You might want to:</p>
            <ul>
              <li><Link to="/">Return to our homepage</Link></li>
              <li><Link to="/services">Check our services</Link></li>
              <li><Link to="/locations">Find a location near you</Link></li>
              <li><Link to="/contact">Contact us for help</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound; 
export const storeData = {
    epTrue: {
        name: 'EP True',
        address: {
            street: '5018 EP True Parkway',
            cityState: 'West Des Moines, IA 50266'
        },
        phone: '(515) 223-8880',
        hours: {
            weekday: 'Mon-Fri: 6:30am-7pm',
            saturday: 'Sat: 9am-1pm',
            sunday: 'Sun: Closed'
        },
        specialties: [
            'Wedding Gown Preservation',
            'High-End Designer Clothing',
            'Leather & Suede',
            'Same-Day Service Available by requestif dropped off before 9am'
        ],
        services: [
            'Full-Service Dry Cleaning',
            'Wash & Fold',
            'Alterations',
            'Free Pickup & Delivery'
        ],
        description: `Our EP True location is our flagship store, featuring state-of-the-art cleaning equipment and specialized care for designer garments. We offer same-day service for many items when dropped off before 9am on weekdays.`
    },
    greenway: {
        name: 'Greenway',
        address: {
            street: '8850 University Ave',
            cityState: 'West Des Moines, IA'
        },
        phone: '(515) 979-6340',
        hours: {
            weekday: 'Mon-Fri: 8am-7pm',
            saturday: 'Sat: 9am-1pm',
            sunday: 'Sun: Closed'
        },
        specialties: [
            'Full-Service Dry Cleaning',
            'Expert Alterations',
            'Drive-thru Service'
        ],
        services: [
            'Dry Cleaning',
            'Wash & Fold',
            'Alterations',
        ],
        description: `Our Greenway Plaza location offers convenient access for West Des Moines residents with expert dry cleaning services and alterations. We pride ourselves on quality service and attention to detail.`
    },
    fortysecond: {
        name: '42nd Street',
        address: {
            street: '863 42nd St',
            cityState: 'Des Moines, IA'
        },
        phone: '(515) 279-0752',
        hours: {
            weekday: 'Mon-Fri: 8am-5pm',
            saturday: 'Sat: 9am-1pm',
            sunday: 'Sun: Closed'
        },
        specialties: [
            'Expert Garment Care',
            'Professional Alterations',
            'Wedding Gown Care',
            'Leather Cleaning'
        ],
        services: [
            'Dry Cleaning',
            'Wash & Fold',
            'Alterations',
            'Free Delivery'
        ],
        description: `Located in the heart of Des Moines, our 42nd Street location has been serving the community for years with professional dry cleaning and alteration services. Convieient loading zone parking is avaialble right outside our front door for northbound traffic.`
    },
    parkave: {
        name: 'Park Ave',
        address: {
            street: '4136 Park Ave',
            cityState: 'Des Moines, IA'
        },
        phone: '(515) 287-4797',
        hours: {
            weekday: 'Mon-Fri: 9am-5pm',
            saturday: 'Sat: 9am-12pm',
        },
        specialties: [
            'Expert Alterations',
            'Wedding Gown Care',
            'Professional Pressing'
        ],
        services: [
            'Dry Cleaning',
            'Alterations',
            'Wash & Fold',
            'Free Delivery'
        ],
        description: `Our Park Avenue location provides convenient dry cleaning services to the South Des Moines area. We specialize in expert alterations and professional garment care. Make sure to take advantage of our overnight dropbox. For your convieience, the entrance near the sliding door is a drive through for drop off and pick up.`
    },
    grimes: {
        name: 'Grimes',
        address: {
            street: '1451 SE Gateway Cir',
            cityState: 'Grimes, IA'
        },
        phone: '(515) 986-1106',
        hours: {
            weekday: 'Mon-Fri: 10am-6pm',
            saturday: 'Sat: 9am-1pm',
        },
        specialties: [
            'Full-Service Dry Cleaning',
            'Expert Alterations',
            'Wedding Gown Care'
        ],
        services: [
            'Dry Cleaning',
            'Wash & Fold',
            'Alterations',
            'Free Delivery'
        ],
        description: `Serving the Grimes community with quality dry cleaning and alteration services. Our convenient location and extended hours make it easy to keep your wardrobe looking its best. Make sure to take advantage of our overnight dropbox.`
    },
    ankeny: {
        name: 'Ankeny',
        address: {
            street: '917 E 1st St',
            cityState: 'Ankeny, IA'
        },
        phone: '(515) 965-0596',
        hours: {
            weekday: 'Mon-Fri: 6:30am-7pm',
            saturday: 'Sat: 9am-3pm',
        },
        specialties: [
            'Professional Dry Cleaning',
            'Expert Alterations',
            'Wedding Gown Care',
            'Same-Day Service by request if dropped off before 8:30am weekdays'
        ],
        services: [
            'Dry Cleaning',
            'Wash & Fold',
            'Alterations',
            'Free Delivery'
        ],
        description: `Our Ankeny location offers early morning hours and professional dry cleaning services to the growing Ankeny community. We pride ourselves on quality service and attention to detail. If requested, items can be picked up the same day if they arrive at our plant by 8:30 AM on weedays. No guarentees, but we do our best to keep you happy.`
    },
    sw9th: {
        name: 'Southwest 9th',
        address: {
            street: '6115 SW 9th St',
            cityState: 'Des Moines, IA'
        },
        phone: '(515) 285-3131',
        hours: {
            weekday: 'Mon-Fri: 8am-5pm',
            saturday: 'Sat: 10am-12pm',

        },
        specialties: [
            'Full-Service Dry Cleaning',
            'Professional Alterations',
            'Wedding Gown Care',
        ],
        services: [
            'Dry Cleaning',
            'Wash & Fold',
            'Alterations',
            'Free Delivery'
        ],
        description: `Our Southwest 9th Street location provides convenient dry cleaning services to the South Des Moines area. We offer extended hours and professional garment care services.`
    },
    boone: {
        name: 'Boone',
        address: {
            street: '909 8th St',
            cityState: 'Boone, IA'
        },
        phone: '(515) 432-4853',
        hours: {
            weekday: 'Mon-Fri: 8am-1pm',
            saturday: 'Sat: 10am-12pm',
        },
        specialties: [
            'Professional Dry Cleaning',
            'Expert Alterations',
            'Wedding Gown Care',
        ],
        services: [
            'Dry Cleaning',
            'Wash & Fold',
            'Alterations',
            'Free Delivery'
        ],
        description: `Serving the Boone community with quality dry cleaning and alteration services. We offer professional garment care with a focus on customer service and convenience.`
    }
}; 
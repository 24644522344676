import React from 'react';
import '../pages/stores/StorePages.css';  // Using the same CSS as your store pages

function StoreTemplate({ storeData, storeImage }) {
  return (
    <div className="store-page">
      <div className="store-image">
        <img 
            src={storeImage} 
            alt={storeData.name} 
            className="store-image"
        />
      </div>
      
      <div className="store-content">
        <h1>{storeData.name}</h1>
        
        <div className="store-details">
          <div className="contact-info">
            <p>
              <i className="fas fa-map-marker-alt"></i>
              {storeData.address.street}<br />
              <span className="city-state">{storeData.address.cityState}</span>
            </p>
            <p><i className="fas fa-phone"></i> {storeData.phone}</p>
            <div className="hours">
              <p><i className="fas fa-clock"></i> {storeData.hours.weekday}</p>
              <p><i className="fas fa-clock"></i> {storeData.hours.saturday}</p>
            </div>
          </div>

          <div className="store-description">
            <p>{storeData.description}</p>
          </div>

          <div className="specialties">
            <h2>Store Specialties</h2>
            <ul>
              {storeData.specialties.map((specialty, index) => (
                <li key={index}><i className="fas fa-star"></i> {specialty}</li>
              ))}
            </ul>
          </div>

          <div className="services">
            <h2>Services Offered</h2>
            <ul>
              {storeData.services.map((service, index) => (
                <li key={index}><i className="fas fa-check"></i> {service}</li>
              ))}
            </ul>
          </div>

          <div className="store-cta">
            <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${storeData.address.street} ${storeData.address.cityState}`)}`}
               target="_blank" 
               rel="noopener noreferrer" 
               className="direction-button">
              <i className="fas fa-directions"></i> Get Directions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreTemplate; 
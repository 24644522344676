import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home';
import Services from './pages/Services';
import Locations from './pages/Locations';
import About from './pages/About';
import Corporate from './pages/Corporate';
import Sensene from './pages/Sensene';
import FreeDelivery from './pages/FreeDelivery';
import EPTrue from './pages/stores/EPTrue';
import Greenway from './pages/stores/Greenway';
import FortySecond from './pages/stores/FortySecond';
import ParkAve from './pages/stores/ParkAve';
import Grimes from './pages/stores/Grimes';
import Ankeny from './pages/stores/Ankeny';
import SW9th from './pages/stores/SW9th';
import Boone from './pages/stores/Boone';
import Footer from './components/Footer/Footer';
import Contact from './pages/Contact';
import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';
import './App.css';
import ReactGA from "react-ga4";
import { useEffect } from 'react';

// Initialize GA4
ReactGA.initialize("G-66VF4E7GW3");

// Separate component for analytics
function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

// Main App component
function App() {
  return (
    <Router>
      <div className="App">
        <AnalyticsTracker />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Corporate" element={<Corporate />} />
          <Route path="/Locations" element={<Locations />} />
          <Route path="/About" element={<About />} />
          <Route path="/sensene" element={<Sensene />} />
          <Route path="/delivery" element={<FreeDelivery />} />
          <Route path="/stores/eptrue" element={<EPTrue />} />
          <Route path="/stores/greenway" element={<Greenway />} />
          <Route path="/stores/42nd" element={<FortySecond />} />
          <Route path="/stores/parkave" element={<ParkAve />} />
          <Route path="/stores/grimes" element={<Grimes />} />
          <Route path="/stores/ankeny" element={<Ankeny />} />
          <Route path="/stores/sw9th" element={<SW9th />} />
          <Route path="/stores/boone" element={<Boone />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
import React from 'react';
import { Link } from 'react-router-dom';
import './ThankYou.css';

const ThankYou = () => {
    return (
        <div className="thank-you-container">
            <div className="thank-you-content">
                <h1>Thank You for Signing Up!</h1>
                <div className="message-box">
                    <p>We've received your information and will be in touch soon.</p>
                    <p>Check your email for confirmation and next steps.</p>
                    <div className="contact-info">
                        <p>Questions? Contact us:</p>
                        <p className="phone">(515) 986-1106</p>
                    </div>
                </div>
                <Link to="/" className="home-button">
                    Return to Home
                </Link>
            </div>
        </div>
    );
};

export default ThankYou; 
import React from 'react';
import './Sensene.css';

function Sensene() {
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="services">
                    <section className="services-section">
                        <div className="letter-card">
                            <h1>Our Commitment to Clean, Safe Dry Cleaning</h1>
                            
                            <p>Hey Gang,</p>
                            
                            <p>As of December 2024, the EPA has officially banned the use of the dry chemical Perchloroethylene, commonly known as Perc. You may remember the toxic train derailment in Ohio a few years back—the main chemical involved, PCE, is the same one standard dry cleaners have relied on for decades. Not only is Perc a known carcinogen, but it's also considered a "forever chemical," causing long-term environmental harm when spilled.</p>
                            
                            <p>At Crown Cleaners, we decided to move away from Perc in early 2020 for the health of our employees and our customers. Cleaning clothes with a petroleum-based solvent that poses such serious risks didn't sit right with us.</p>
                            
                            <p>We made a significant investment in new technology and switched to Sensene, a state-of-the-art alcohol-based solvent. Sensene is non-toxic, fresh, and biodegradable, breaking down into simple alcohol. What's more, the cleaning technology we adopted with this change allows us to get garments twice as clean as traditional dry cleaning solvents—and about three times as clean as your home washing machine!</p>
                            
                            <p>This transition has been a game-changer. We feel great knowing we can offer you a better, safer product while providing a healthier, more comfortable working environment for our team. And in the unlikely event of a spill, we have peace of mind knowing it won't create a lasting environmental issue on the land where we work and live.</p>
                            
                            <p>At Crown Cleaners, it's all about embracing better technology and working toward a safer, more sustainable future for everyone around us.</p>
                            
                            <p>If you have any questions, feel free to reach out to me directly at <a href="mailto:ben@crown-cleaners.com">ben@crown-cleaners.com</a>.</p>
                            
                            <p>On behalf of the Anderson Family, thank you for trusting us with your garments and for supporting our commitment to a better tomorrow.</p>
                            
                            <p className="signature">
                                Warm regards,<br />
                                Ben Anderson<br />
                                Crown Cleaners
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Sensene; 
import React from 'react';
import { Link } from 'react-router-dom';
import './Corporate.css';

function Corporate() {
  const benefits = [
    {
      icon: 'fa-handshake',
      title: 'Dedicated Account Manager',
      description: 'Personal point of contact for all your business needs'
    },
    {
      icon: 'fa-percent',
      title: 'Corporate Discounts',
      description: 'Special pricing for business accounts'
    },
    {
      icon: 'fa-truck',
      title: 'Free Pickup & Delivery',
      description: 'Convenient service at your office location'
    }
  ];

  const industries = [
    {
      icon: 'fa-hotel',
      title: 'Hotels',
      description: 'Complete laundry solutions for hospitality businesses'
    },
    {
      icon: 'fa-building',
      title: 'Office Buildings',
      description: 'Professional care for corporate workwear'
    },
    {
      icon: 'fa-utensils',
      title: 'Restaurants',
      description: 'Expert cleaning for uniforms and linens(and so many tablecloths)'
    },
    {
      icon: 'fa-spa',
      title: 'Spas & Salons',
      description: 'Specialized care for spa and salon textiles'
    },
    {
      icon: 'fa-bed',
      title: 'AirBNB Linens',
      description: 'Laundry services for towels, sheets, and comforters for your short-term rental properties'
    },
    {
      icon: 'fa-music',
      title: 'High School Band Uniforms',
      description: 'Professional cleaning for band uniforms and accessories. Mascots? Sure.'
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="corporate">
          <header className="page-header">
            <h1>Industries We Serve</h1>
          </header>

          {/* Industries We Serve Section */}
          <div className="industries-section">
            
            <div className="industries-grid">
              {industries.map((industry, index) => (
                <div key={index} className="industry-card">
                  <i className={`fas ${industry.icon}`}></i>
                  <h3>{industry.title}</h3>
                  <p>{industry.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Corporate Benefits Section */}
          <div className="corporate-benefits">
            <div className="page-header">
              <h1>Stuff You Get</h1>
            </div>
            <div className="benefits-grid">
              {benefits.map((benefit, index) => (
                <div key={index} className="benefit-card">
                  <i className={`fas ${benefit.icon}`}></i>
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Contact CTA Section */}
          <div className="corporate-cta">
            <h2>Start Your Account Today</h2>
            <p>Contact us to discuss your business needs and custom solutions</p>
            <Link to="/contact" className="cta-button">Get Started</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Corporate;